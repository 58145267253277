<template>
  <div class="main">
    <div class="search">
      <el-select v-model="log.byField" size="mini"
                 placeholder="검색조건"
                 style="width:120px;margin-left: 10px">
        <el-option label="전체" :value="null">전체</el-option>
        <el-option label="회원닉네임" :value="managerConst.LogLogin.SEARCH_BYFEILD_NICKNAME">회원닉네임
        </el-option>
        <el-option label="IP" :value="managerConst.LogLogin.SEARCH_BYFEILD_IP">IP
        </el-option>
      </el-select>
      <el-input type="text" size="mini" v-model="log.searchString" placeholder="아이디/IP"
                style="width: 150px;margin-left: 5px"></el-input>
      <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
      <el-button type="primary" @click="getLogList" size="mini" style="margin-left: 10px">검색</el-button>
      <el-button type="success" @click="checkedAlram" size="mini" style="margin-left: 30px">전체알람확인</el-button>

    </div>

    <div class="data">
      <el-table
          :data="logList"
          style="width: 100%"
          max-height="730"
          border>

        <el-table-column
            label="로그인회원"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.me.nickname }}
          </template>
        </el-table-column>
        <el-table-column
            label="로그인날짜"
            width="130">
          <template slot-scope="scope">
            {{ scope.row.logintime|datef('MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
            label="IP"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.ip }}
          </template>
        </el-table-column>
        <el-table-column
            label="중복회원"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.otheruser.nickname }}
          </template>
        </el-table-column>
        <el-table-column
            label="로그인날짜"
            width="130">
          <template slot-scope="scope">
            {{ scope.row.otherlogintime|datef('MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
            label=""
            width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.alram == managerConst.UNCHECKED" class="text-red">미확인</span>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="pagePanel">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[25, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal">
      </el-pagination>
    </div>

  </div>

</template>

<script>

import OnlineUserComp from "../../components/administrator/OnlineUserComp";
import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
import {updateGroup} from "../../network/manager/groupRequest";
import {manager} from "../../common/administrator/managerMixin";
import managerConst from "../../common/administrator/managerConst";
import {getLogCashPointList} from "../../network/manager/logCashPointRequest";
import {getModifiedHistoryList, updateAllModifiedHistory} from "../../network/manager/logModifyRequest";
import Vue from "vue";
import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
import {getLoginLogList, getRepeateLoginList, repeateAlramChecked} from "../../network/manager/logLoginRequest";
import DateSelectorComp from "../../components/administrator/DateSelectorComp";
import {Loading} from "element-ui";
import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";

Vue.prototype.$userEditToast = UserEditToast
Vue.prototype.$partnerEditToast = PartnerEditToast
Vue.prototype.$agentEditToast = AgentEditToast
export default {
  name: "ManagerRepeateLoginHistory",
  mixins: [manager],
  components: {DateSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
  data() {
    return {
      startDate: '',
      endDate: '',
      pageNum: 1,
      pageSize: 25,
      pageTotal: 0,
      log: {},
      logList: [],
      domainList: []

    }
  },
  methods: {
    editPartner(id, mode) {
      this.$agentEditToast({'agentId': id, 'mode': mode})
    },
    editUser(userId, mode) {
      this.$userEditToast({'userId': userId, 'mode': mode})
    },
    getLogList() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.log.startDate = this.startDate;
      this.log.endDate = this.endDate;
      getRepeateLoginList(this.log, this.pageNum, this.pageSize).then(res => {
        this.logList = res.data.data
        this.pageTotal = res.data.total
        loadingInstance.close()
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getLogList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getLogList();
    },
    setStartDate() {
      this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
    },
    setEndDate() {
      this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
    },
    checkedAlram(){
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      repeateAlramChecked().then(res=>{
        loadingInstance.close()
        this.getLogList()
      })
    },
  },
  created() {
    this.startDate = this.$moment().subtract(30, 'days').format('yyyy-MM-DD')
    this.endDate = this.$moment().format('yyyy-MM-DD')
    this.getLogList()
  },
}
</script>

<style scoped>

</style>